/* eslint-disable no-undef */
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  CheckoutTender,
  Money,
  RequestError,
  TenderType,
} from '@open-tender/types'
import {
  selectApi,
  selectBrand,
  submitOrderPay,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { FormError, Loading } from 'components'

const ApplePayView = styled.div`
  margin: 2rem 0 1rem;
`

const ApplePayButton = styled.button`
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  width: 100%;
  height: 5rem;
`

const ApplePayChecking = styled.div`
  width: 100%;
  padding: 0 0 1rem;
  text-align: center;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.colors.primary};

  > div {
    display: inline-block;
    margin: 0 0 1rem;
  }
`

const paymentSessionConfig = {
  countryCode: 'US',
  currencyCode: 'USD',
  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
  merchantCapabilities: ['supports3DS'],
  // total: { label: 'Your Merchant Name', amount: '10.00' },
}

// https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
const checkApplePayWithActiveCard = (
  applePayMerchantId: string,
  setChecking: (checking: boolean) => void
) => {
  if (applePayMerchantId && (window as any).ApplePaySession) {
    setChecking(true)
    const promise = (
      window as any
    ).ApplePaySession.canMakePaymentsWithActiveCard(applePayMerchantId)
    return promise
      .then((canMakePayments: boolean) => {
        const canPay = (window as any).ApplePaySession.canMakePayments()
        // eslint-disable-next-line no-console
        console.log('canMakePayments', canMakePayments, canPay)
        return canMakePayments || canPay
      })
      .catch(() => false)
      .finally(() => setChecking(false))
  } else {
    return new Promise((resolve) => resolve(false))
  }
}

const validateSession = async (
  api: any,
  validationURL: string,
  callback: (merchant_session: any) => void
) => {
  try {
    const host = window.location.hostname
    const { merchant_session } = await api.postApplePayValidate(
      host,
      validationURL
    )
    callback(merchant_session)
  } catch (err) {
    const error = err as RequestError
    if (error && error.detail) {
      throw new Error(error.detail)
    } else if (error && error.message) {
      throw new Error(error.message)
    }
  }
}

const CheckoutApplePay = ({
  amount,
  error,
  addTender,
  removeTender,
}: {
  amount: Money
  error: string | null
  addTender: (data: CheckoutTender) => void
  removeTender: (data: TenderType) => void
}) => {
  const dispatch = useAppDispatch()
  const [checking, setChecking] = useState(false)
  const [showApplePay, setShowApplePay] = useState(false)
  const [errMsg, setErrMsg] = useState<any>(null)
  const { title: label, applePayMerchantId } = useAppSelector(selectBrand) || {}
  const api = useAppSelector(selectApi)
  const config = { ...paymentSessionConfig, total: { label, amount } }
  const nonZero = parseFloat(amount) > 0
  const show = nonZero && (checking || showApplePay || errMsg)

  useEffect(() => {
    checkApplePayWithActiveCard(applePayMerchantId ?? '', setChecking).then(
      (show: boolean) => setShowApplePay(show)
    )
  }, [applePayMerchantId])

  useEffect(() => {
    if (error) setErrMsg(error)
  }, [error])

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    const applePaySession = new (window as any).ApplePaySession(6, config)
    applePaySession.begin()

    applePaySession.onvalidatemerchant = (
      evt: ApplePayJS.ApplePayValidateMerchantEvent
    ) => {
      validateSession(api, evt.validationURL, (merchantSession: any) => {
        applePaySession.completeMerchantValidation(merchantSession)
      }).catch((err) => {
        applePaySession.abort()
        setErrMsg(err.detail || err.message)
      })
    }

    applePaySession.onpaymentauthorized = (
      evt: ApplePayJS.ApplePayPaymentAuthorizedEvent
    ) => {
      const tender: CheckoutTender = {
        tender_type: 'APPLE_PAY',
        amount: amount,
        token: evt.payment.token,
      }
      addTender(tender)
      dispatch(submitOrderPay(false)).then((action) => {
        const { type } = action || {}
        const isFufilled = type && type === 'checkout/SubmitOrderPay/fulfilled'
        if (isFufilled) {
          applePaySession.completePayment(
            (window as any).ApplePaySession.STATUS_SUCCESS
          )
        } else {
          applePaySession.completePayment(
            (window as any).ApplePaySession.STATUS_FAILURE
          )
          removeTender('APPLE_PAY')
        }
      })
    }
  }

  return show ? (
    <ApplePayView>
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      {checking ? (
        <ApplePayChecking>
          <Loading />
          <p>Checking for Apple Pay support...</p>
        </ApplePayChecking>
      ) : showApplePay ? (
        <ApplePayButton onClick={onClick} />
      ) : null}
    </ApplePayView>
  ) : null
}

export default CheckoutApplePay
