import { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  CheckoutTenderGiftCard,
  Money,
  isGiftCardCheckoutTender,
} from '@open-tender/types'
import { formatDollars, checkAmountRemaining } from '@open-tender/utils'
import {
  selectCheckout,
  selectContent,
  selectCustomer,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { cardIconsMap } from 'components/cardIcons'
import CheckoutSection from './CheckoutSection'
import CheckoutButton from './CheckoutButton/CheckoutButton'
import CheckoutGiftCard from './CheckoutGiftCard'
import CheckoutGiftCardsGuest from './CheckoutGiftCardsGuest'

const CheckoutGiftCardsView = styled.div`
  margin: 1.5rem 0 0;
`

const CheckoutGiftCards = () => {
  const dispatch = useAppDispatch()
  const { auth } = useAppSelector(selectCustomer)
  const { checkout: config } = useAppSelector(selectContent) || {}
  const { check, form } = useAppSelector(selectCheckout)
  const giftCards =
    check?.customer && check.customer.gift_cards
      ? check.customer.gift_cards || []
      : []
  const applied = useMemo(() => {
    const giftCardTenders = form.tenders.filter((i) =>
      isGiftCardCheckoutTender(i)
    ) as CheckoutTenderGiftCard[]
    return giftCardTenders.reduce(
      (obj, i) => ({ ...obj, [i.card_number]: i.amount }),
      {} as Record<string, Money>
    )
  }, [form.tenders])
  const total = check?.totals.total
  const deposit = check?.deposit
  const amountDue = deposit || total
  const amountRemaining = amountDue
    ? checkAmountRemaining(amountDue, form.tenders)
    : 0.0
  const isPaid = Math.abs(amountRemaining).toFixed(2) === '0.00'

  const applyGiftCard = (cardNumber: string, balance: Money) => {
    if (!amountDue) return
    const giftCardTenders = form.tenders.filter(
      (i) => i.tender_type === 'GIFT_CARD'
    )
    let remaining = giftCardTenders.length
      ? checkAmountRemaining(amountDue, giftCardTenders)
      : parseFloat(amountDue)
    const amount = Math.min(remaining, parseFloat(balance)).toFixed(2)
    if (parseFloat(amount) <= 0.0) return
    const newGiftCard = {
      tender_type: 'GIFT_CARD',
      card_number: cardNumber,
      balance: balance,
      amount: amount,
    }
    dispatch(updateForm({ tenders: [...form.tenders, newGiftCard] }))
  }

  const removeGiftCard = (cardNumber: string) => {
    if (!amountDue) return
    const filtered = form.tenders.filter(
      (i) => !isGiftCardCheckoutTender(i) || i.card_number !== cardNumber
    )
    const nonGiftCard = filtered.filter((i) => i.tender_type !== 'GIFT_CARD')
    const giftCard = filtered.filter(
      (i) => i.tender_type === 'GIFT_CARD'
    ) as CheckoutTenderGiftCard[]
    let remaining = checkAmountRemaining(amountDue, nonGiftCard)
    const adjusted = giftCard.map((i) => {
      const newAmount = Math.min(remaining, parseFloat(`${i.balance}`))
      remaining -= newAmount
      return { ...i, amount: newAmount.toFixed(2) }
    })
    const nonZero = adjusted.filter((i) => i.amount !== '0.00')
    const adjustedOther = nonGiftCard.map((i) => {
      const newAmount = parseFloat(i.amount) + remaining
      remaining = 0.0
      return { ...i, amount: newAmount.toFixed(2) }
    })
    const nonZeroOther = adjustedOther.filter((i) => i.amount !== '0.00')
    dispatch(updateForm({ tenders: [...nonZeroOther, ...nonZero] }))
  }

  return (
    <CheckoutSection title={config?.giftCards.title}>
      <CheckoutGiftCardsView>
        {!auth ? (
          <CheckoutGiftCardsGuest />
        ) : (
          <>
            {giftCards.map((i) => {
              const amount = applied[i.card_number ?? 0]
              const isApplied = !!amount
              const onPress = isApplied
                ? () => removeGiftCard(i.card_number)
                : () => applyGiftCard(i.card_number, i.balance)
              const disabled = !isApplied && isPaid
              const title = `Gift Card ${i.card_number}`
              const subtitle = isApplied
                ? `${formatDollars(amount)} applied to check`
                : `${formatDollars(`${i.balance}`)} available`
              return (
                <CheckoutButton
                  key={i.card_number}
                  icon={cardIconsMap['OTHER']}
                  title={title}
                  subtitle={subtitle}
                  onPress={onPress}
                  isApplied={isApplied}
                  disabled={disabled}
                />
              )
            })}
            <CheckoutGiftCard />
          </>
        )}
      </CheckoutGiftCardsView>
    </CheckoutSection>
  )
}

export default CheckoutGiftCards
