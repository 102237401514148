import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckoutFormCustomer, FormFieldType } from '@open-tender/types'
import { useDineInForm } from '@open-tender/utils'
import {
  checkout,
  resetGuest,
  selectCheckout,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'

const DineInForm = ({ submitText = 'Continue' }: { submitText?: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { form } = useAppSelector(selectCheckout)
  const { first_name, last_name } = form.customer || {}
  const initialData = { first_name, last_name }

  const submitGuest = useCallback(
    (data: CheckoutFormCustomer) => {
      // dispatch(resetGuest())
      dispatch(updateForm({ customer: data }))
      dispatch(checkout())
      navigate('/checkout')
    },
    [dispatch, navigate]
  )

  const { submitRef, fields, data, errors, handleChange, handleSubmit } =
    useDineInForm(initialData, submitGuest)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    return () => {
      dispatch(resetGuest())
    }
  }, [dispatch])

  return (
    <form id="dine-in-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef}>{submitText}</ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default DineInForm
