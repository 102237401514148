import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { CheckoutConfigGratuityAmount, Money } from '@open-tender/types'
import { formatDollars, formatPercentage } from '@open-tender/utils'
import {
  selectCheckout,
  selectContent,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { Button, Checkmark, Input } from 'components'
import CheckoutSection from './CheckoutSection'
import CheckoutTipButton from './CheckoutTipButton'

const CheckoutTipView = styled.div`
  margin: 1.5rem 0 0;
`

const CheckoutTipOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 0.5rem;
  }
`

const CheckoutTipCustom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0 0;
`

const CheckoutTipCustomInput = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 2rem 0 0;
`
const CheckoutTipCustomCheckmark = styled.div`
  position: absolute;
  top: 0;
  right: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CheckoutTipCustomButton = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

const defaultOptions: CheckoutConfigGratuityAmount[] = [
  { amount: '10.00', percent: '10.00' },
  { amount: '15.00', percent: '15.00' },
  { amount: '18.00', percent: '18.00' },
  { amount: '20.00', percent: '20.00' },
]

const CheckoutTip = () => {
  const dispatch = useAppDispatch()
  const { check, form, loading } = useAppSelector(selectCheckout)
  const { checkout: config } = useAppSelector(selectContent) || {}
  const tipSettings = check?.config.gratuity
  const { has_tip, options } = tipSettings || {}
  const tipOptions = options || defaultOptions
  const initialTip =
    form.tip && !tipOptions.find((i) => i.amount === form.tip) ? form.tip : ''
  const [customTip, setCustomTip] = useState(initialTip)
  const customTipApplied =
    customTip && customTip === check?.totals.tip ? true : false

  useEffect(() => {
    if (has_tip && !form.tip && loading !== 'pending') {
      setCustomTip('')
      dispatch(updateForm({ tip: check?.totals.tip }))
    }
  }, [has_tip, form.tip, loading, check?.totals.tip, dispatch])

  if (!has_tip) return null

  const chooseTip = (amount: Money) => {
    setCustomTip('')
    dispatch(updateForm({ tip: amount }))
    dispatch(validateOrder())
  }

  const handleCustomTip = (txt: string) => {
    setCustomTip(txt)
  }

  const applyCustomTip = () => {
    const formatted = parseFloat(customTip).toFixed(2)
    setCustomTip(formatted)
    dispatch(updateForm({ tip: formatted }))
    dispatch(validateOrder())
  }

  return (
    <CheckoutSection title={config?.gratuity.title}>
      <CheckoutTipView>
        <CheckoutTipOptions>
          {tipOptions.map((i) => {
            const isApplied = !customTip && form.tip === i.amount
            return (
              <CheckoutTipButton
                key={`${i.percent}-${i.amount}`}
                title={`${formatPercentage(i.percent)}%`}
                subtitle={formatDollars(i.amount)}
                onPress={() => chooseTip(i.amount)}
                isApplied={isApplied}
                disabled={isApplied}
              />
            )
          })}
        </CheckoutTipOptions>
        <CheckoutTipCustom>
          <CheckoutTipCustomInput>
            <Input
              label="Enter custom tip"
              name="custom_tip"
              type="number"
              value={customTip}
              onChange={(evt) => handleCustomTip(evt.target.value)}
              style={{ margin: 0 }}
            />
            {customTipApplied && (
              <CheckoutTipCustomCheckmark>
                <Checkmark />
              </CheckoutTipCustomCheckmark>
            )}
          </CheckoutTipCustomInput>
          <CheckoutTipCustomButton>
            <Button
              onClick={applyCustomTip}
              disabled={customTip === '' || customTipApplied}
              size="small"
              color="secondary"
            >
              Apply
            </Button>
          </CheckoutTipCustomButton>
        </CheckoutTipCustom>
      </CheckoutTipView>
    </CheckoutSection>
  )
}

export default CheckoutTip
